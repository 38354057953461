import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";



export default function Transport() {
  return (
    <Layout pageTitle="Northwest Transport Hub">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Northwest Transport Hub - USI Projects</title>
        <meta name="description" content="USI worked with Translink, Turleys and the local community in Derry-Londonderry to reimagine what a community space in a train station could be."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

       <section class="text-gray-700 body-font heading-container-transport">

       <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Northwest Transport Hub</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                Translink and Turleys</p>
              </div>
          </section>
        </div>
      </section>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-green-500">Overview</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl   ">USI worked
                  with Translink, Turleys and the local community in Derry-Londonderry to reimagine what a community
                  space in a train station could be. We have been supporting the programming and culture of the space
                  and developing longstanding relationships between the community, local organisations and
                  Translink.<br/><br/>

                  Through a public opening over the Halloween half-term in October 2019, we used a creative installation
                  in the form of a giant interactive balloon-ghost train and programmed the space with workshops,
                  performances, events and activities to understand what the community wanted from a new public space in
                  the train station.<br/><br/>

                  Over 2000 people gave their opinion throughout the week, including commuters, passers-by and those who
                  came along to one of the many events.</p>

              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase  ">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0  ">Active
                  Citizenship<br/>
                  Community</p>
                <p class="text-2xl font-bold uppercase   mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0   ">Citizen
                  Engagement<br/>
                  Pop-up Installations<br/>Programming Spaces<br/>Events Management</p>
                <p class="text-2xl font-bold uppercase    mt-8">Location</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0   ">Derry-Londonderry
                </p>
              </div>
            </div>
          </section>
        </div>
      </section>



      <div class="flex flex-wrap md:mb-0 md:mt-16">
        <div class="h-auto w-full md:w-3/5 md:mb-0 py-6">
          <img class="w-full h-full pr-2" src="https://ik.imagekit.io/usi/project-pages/IMG_9662_1_o3wPFcBVU.png" alt=""
            width="720px"/></div>
        <div class="h-auto w-full md:w-2/5 md:mb-0">
          <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/IMG_1614_1_ZdzbSd7y57Yu6.png" alt=""
            width="720px"/></div>
      </div>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="w-full md:px-40 xl:w-1/3 py-4 xl:px-32 mb-4 md:mb-0">



                <div class="md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-16">

                </div>
              </div>
              <div data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                class="w-full md:w-9/12 md:m-auto xl:w-2/3 py-4 sm:px-8 md:px-24 mb-4 md:mb-0  ">

                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="text-xl md:text-2xl lg:text-3xl  p-6 md:pl-0 md:pr-0 md:pb-0">
                  On the back of the success of the Halloween intervention, we then hosted a second engagement in
                  December specifically around the Christmas period and the run-up to it. These events had a lot of
                  engagement from people going to Belfast to do Christmas shopping, and so the inclusion of a craft
                  market in the community space gave people the opportunity to both spend time in their own city and to
                  support local makers and businesses through buying products. This Christmas intervention also saw a
                  lot of young people engaging with our teepee structure supporting Cancer Focus NI with attaching
                  messages of reflection and hope for Christmas and the upcoming year.
                </p>

              </div>
            </div>
          </section>
        </div>
      </section>



      <img data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" class="w-full md:pl-20 md:pr-20  "
        src="../img/projects/yoga.png" alt=""/>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pb-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">


              <div data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                class="w-full md:m-auto xl:w-2/3 py-4 sm:px-8 md:px-24 mb-4 md:mb-0  ">

                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="text-xl md:text-2xl lg:text-3xl  p-6 md:pl-0 md:pr-0 md:pb-0">
                  The work has led to a new community-owned space in the city and opened a conversation on new uses for
                  community-owned spaces in train stations. This has been supported through a pilot scheme, allowing
                  many local organisations and businesses the opportunity to pilot how they use this space, with a
                  calendar of regular and one-off events now programmed from yoga sessions to pop-up markets and even
                  gigs and performances.<br/><br/>

                  The NW Transport Hub shows how previously underutilised spaces can be reshaped and reframed as
                  thriving community social hubs for years to come.
                </p>

              </div>
              <div class="w-full md:w-9/12 md:px-40 xl:w-1/3 py-4 xl:px-32 mb-4 md:mb-0">



              </div>




            </div>
          </section>
        </div>
      </section>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-green-500">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../latrobe" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
